import {useEffect, useState} from 'react';
import {Container, Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {Link, useParams} from "react-router-dom";
import WCService from '../services/WCService';


function Menu() {
    const [activitiesCategory, setActivitiesCategory] = useState([]);
    const productCategoryExcluded = ['uncategorized', 'pedalare-in-abruzzo'];
    const param = useParams();

    useEffect(() => {
      getActivitiesCategory();
    },[]);
    // console.log(param);

    async function getActivitiesCategory (){
      let activityCategories = await WCService.getProductsCategories();
      activityCategories = activityCategories.data;
      setActivitiesCategory(activityCategories);

      // await Promise.all(activityCategories.map(async (activityCategory, index) => {
      //     if( !productCategoryExcluded.includes(activityCategory.slug) ){
      //       // return activityCategory;
      //       setActivitiesCategory( activitiesCategory.push(activityCategory) );
      //       console.warn(activitiesCategory);
      //     }
      //   })
      // );
  }


    return (
      <>
        <Navbar bg="light" expand="lg" className="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
          <Container>
            <Link to="/">
              {/* <Navbar.Brand href="/"></Navbar.Brand> */}
              <Navbar.Brand>
                {process.env.REACT_APP_NAME}
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/">Home</Nav.Link>
                {/* <Nav.Link as={Link} to="/link">Link</Nav.Link> */}
                {/* <Nav.Link as={Link} to="/attivita">Attività</Nav.Link> */}

                <NavDropdown title="Attività" id="basic-nav-dropdown">
                  {activitiesCategory && activitiesCategory.length ?
                      activitiesCategory.map((activityCategory, index) => {
                        if( !productCategoryExcluded.includes(activityCategory.slug) ){
                          return <NavDropdown.Item key={index} as={Link} 
                          to={`/attivita/${activityCategory.slug}`}>{activityCategory.name}</NavDropdown.Item>
                        }
                      }) : null}
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to={`/attivita`}>Tutte le attività</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/il-progetto" >Il Progetto</Nav.Link>
                {/* <Link to="/mimmo">MIMMO</Link> */}
                {/* <NavDropdown title="Consigliati" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    )
}

export default Menu;