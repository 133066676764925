import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    useLocation,
    Link,
    Navigate,
    Outlet
  } from "react-router-dom";
import Home from '../pages/Home';
import Mimmo from "../pages/Mimmo";
import IlProgetto from "../pages/IlProgetto";
import Attivita from '../pages/Attivita';
import AttivitaSingola from '../pages/AttivitaSingola';
import NotFound from '../pages/NotFound';
import { AnimatePresence } from "framer-motion";
import DettaglioEvento from "../components/DettaglioEvento";




export default function AnimatedRoutes(){
    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/il-progetto" element={<IlProgetto />} />
                <Route path="/attivita" element={<Attivita />} />
                <Route path="/attivita/:attivita" element={<AttivitaSingola />}/>
                <Route path="/attivita/:attivita/:singola" element={<DettaglioEvento />} />
                <Route path="/mimmo" element={<Mimmo />} />
                <Route path="/mimmo/:mimmoId" element={<Mimmo />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </AnimatePresence>
    )
}