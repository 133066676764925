import { useState } from 'react';
import { Link } from "react-router-dom";


function Footer(props) {
    const showSiteTitle = (
        <>{props.siteTitle}</>
    )
    const currentYear = new Date().getFullYear();

    

    return (
        <footer className="footer pt-5 mt-5">
            <hr className="horizontal dark mb-5" />
            <div className="container">
                <div className=" row">
                    <div className="col-md-3 mb-4 ms-auto">
                        <div>
                            <h6 className="text-gradient text-primary font-weight-bolder">{showSiteTitle}</h6>
                        </div>
                        <div>
                            <h6 className="mt-3 mb-2 opacity-8">Social</h6>
                            <ul className="d-flex flex-row ms-n3 nav">
                                <li className="nav-item">
                                    <a className="nav-link pe-1" href="https://www.instagram.com/tourdabruzzo/" target="_blank">
                                    <i className="fab fa-instagram text-lg opacity-8"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pe-1" href="https://www.facebook.com/tourdabruzzo/" target="_blank">
                                    <i className="fab fa-facebook text-lg opacity-8"></i>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link pe-1" href="https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w" target="_blank">
                                    <i className="fab fa-youtube text-lg opacity-8"></i>
                                    </a>
                                </li> */}
                                {/* <li className="nav-item">
                                    <a className="nav-link pe-1" href="https://twitter.com/creativetim" target="_blank">
                                    <i className="fab fa-twitter text-lg opacity-8"></i>
                                    </a>
                                </li> */}
                                {/* <li className="nav-item">
                                    <a className="nav-link pe-1" href="https://dribbble.com/creativetim" target="_blank">
                                    <i className="fab fa-dribbble text-lg opacity-8"></i>
                                    </a>
                                </li> */}
                                {/* <li className="nav-item">
                                    <a className="nav-link pe-1" href="https://github.com/creativetimofficial" target="_blank">
                                    <i className="fab fa-github text-lg opacity-8"></i>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6 col-6 mb-4">
                        {/* <div>
                            <h6 className="text-gradient text-primary text-sm">Company</h6>
                            <ul className="flex-column ms-n3 nav">
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.creative-tim.com/presentation" target="_blank">
                                    About Us
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.creative-tim.com/templates/free" target="_blank">
                                    Freebies
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.creative-tim.com/templates/premium" target="_blank">
                                    Premium Tools
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.creative-tim.com/blog" target="_blank">
                                    Blog
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="col-md-2 col-sm-6 col-6 mb-4">
                        {/* <div>
                            <h6 className="text-gradient text-primary text-sm">Resources</h6>
                            <ul className="flex-column ms-n3 nav">
                            <li className="nav-item">
                                <a className="nav-link" href="https://iradesign.io/" target="_blank">
                                Illustrations
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.creative-tim.com/bits" target="_blank">
                                Bits & Snippets
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.creative-tim.com/affiliates/new" target="_blank">
                                Affiliate Program
                                </a>
                            </li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="col-md-2 col-sm-6 col-6 mb-4">
                        {/* <div>
                            <h6 className="text-gradient text-primary text-sm">Help & Support</h6>
                            <ul className="flex-column ms-n3 nav">
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.creative-tim.com/contact-us" target="_blank">
                                Contact Us
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.creative-tim.com/knowledge-center" target="_blank">
                                Knowledge Center
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://services.creative-tim.com/?ref=ct-soft-ui-footer" target="_blank">
                                Custom Development
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.creative-tim.com/sponsorships" target="_blank">
                                Sponsorships
                                </a>
                            </li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="col-md-2 col-sm-6 col-6 mb-4 me-auto">
                        <div>
                            <h6 className="text-gradient text-primary text-sm">In breve</h6>
                            <ul className="flex-column ms-n3 nav">
                                <li className="nav-item">
                                    <span className="nav-link">
                                        <Link to="/termini-e-condizioni">
                                            Termini e Condizioni
                                        </Link>
                                    </span>
                                </li>
                                <li className="nav-item">
                                    <span className="nav-link">
                                        <Link to="/privacy-policy">
                                            Privacy Policy
                                        </Link>
                                    </span>
                                </li>
                                <li className="nav-item">
                                    <span className="nav-link">
                                        <Link to="/Licenze di utilizzo">
                                            Licenses (EULA)
                                        </Link>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="text-center">
                            <p className="my-4 text-sm">
                            Progetto ideato e realizzato da <a href="https://stefanosponsillo.net" target="_blank">Stefano Sponsillo </a>
                            - Per il template grafico di base : © {currentYear} Soft UI Design System by 
                                <a href="https://www.creative-tim.com" target="_blank"> Creative Tim</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;