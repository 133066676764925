import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import '../assets/css/spinner.css';



export default function Spinner (props) {
    const [show, setShow] = useState('');

    useEffect(() => {
        if(props.show){
            setShow('block');
        }else{
            setShow('none');
        }
    });
    
    

    return (
        <div className="overlay" style={{display:show}}>
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spinner"></span></div>
            </div>
        </div>
    )
}