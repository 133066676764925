import logo from '../logo.svg';
import { useState, useEffect } from 'react';
import { 
  Container,
  Row, 
  Col, 
  Modal, 
  Button,
//   Spinner, 
} from 'react-bootstrap';
import Menu from '../components/Menu';
import CardCustom from '../components/CardCustom';
import Footer from '../components/Footer';
import {motion} from 'framer-motion';
import WCService from '../services/WCService';
import Spinner from '../components/Spinner';
import { useParams } from 'react-router-dom';



function Home() {

    const [activitiesCategory, setActivitiesCategory] = useState([]);
    const [showSpinner, setShowSpinner] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isActive, setIsActive] = useState(false);
    const productCategoryExcluded = ['uncategorized', 'pedalare-in-abruzzo'];
    const param = useParams();

    useEffect(() => {
        getActivitiesCategory();
    },[]);
    

    async function getActivitiesCategory(){
        const activityCategories = await WCService.getProductsCategories();
        setActivitiesCategory(activityCategories.data);
        setShowSpinner(false);
    }



    return (
        <motion.div
            // className="block"
            // onClick={() => setIsActive(!isActive)}
            animate={{
                // rotate: isActive ? 180 : 360
                // rotate: [0, 0, 270, 270, 0]
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
        {/* <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        > */}
            <div className="page-header min-vh-75 hero-opacity">
                {/* <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
                </Spinner> */}
                <Container>
                    <Spinner show={showSpinner}/>
                    <Row className="pt-2 pb-5">
                        <Col><Menu params={param} /></Col>
                    </Row>
                    <Row className="pt-1 mt-5">
                        <Col className='col-12'>
                            <div className="col-lg-12 mx-auto">
                                <h1 className="pt-3 mt-n5 hero-color">Tourdabruzzo.it</h1>
                                <p className="lead mt-3 hero-color" style={{fontWeight: 600}} >Visita l'Abruzzo.
                                        Respira i suoi luogi. Vivi la sua gente.</p>
                            </div>
                            {/* <Button variant="primary" onClick={handleShow}>
                            Launch demo modal
                            </Button> */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <hr className="horizontal dark mb-5" style={{visibility: "hidden"}} />


            <Container>
                <Row>
                    {activitiesCategory && activitiesCategory.length ?
                        activitiesCategory.map((activityCategory, index) => {
                            if( !productCategoryExcluded.includes(activityCategory.slug) ){
                                return <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                    <CardCustom {...activityCategory} />
                                </div>
                            }
                    }) : null}
                </Row>
            </Container>

            <Footer siteTitle = {process.env.REACT_APP_NAME} />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </motion.div>
  );
}

export default Home;
