import { useState } from 'react';
import { 
    Container,
    Row, 
    Col, 
    Modal, 
    Button,
    Spinner, 
    Navbar,
    Nav, 
    NavDropdown, 
  } from 'react-bootstrap';
import Menu from '../components/Menu';
import axios from 'axios';



export default function Hero (props) {
    const heroStyle = {
        backgroundColor: '#e9e8e8de', 
        padding: '50px 10px 10px 10px', 
        borderRadius: '10px'
    }

    const heroImages = {
        climbing    : 'categories/climbing.jpg',
        mountainbike: 'categories/mountainbike.jpg',
        canoa       : 'categories/canoa.jpg',
    }




    return (
        <>
            <div className="page-header min-vh-75" style={{backgroundImage: "url('./assets/img/hero.jpg')"}}>
                {/* <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner> */}

                <Container>
                    <Row className="pt-2 pb-5">
                        <Col><Menu /></Col>
                    </Row>
                    <Row className="pt-1 mt-5">
                        <Col>
                            <div className="col-lg-5 text-center mx-auto" style={heroStyle}>
                                <h1 className="pt-3 mt-n5 hero-color">{props.title}</h1>
                                <p className="lead mt-3 hero-color">
                                    {props.subTitle}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <hr className="horizontal dark mb-5" style={{visibility: "hidden"}} />
        </>
    )
}