import {motion} from 'framer-motion';



export default function NotFound(){
    return (
        <motion.div
            // className="block"
            // onClick={() => setIsActive(!isActive)}
            animate={{
                // rotate: isActive ? 180 : 360
                // rotate: [0, 0, 270, 270, 0]
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
        {/* <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        > */}
            <h1>NON abbiamo trovato la rotta</h1>
        </motion.div>
    )
}