import logo from '../logo.svg';
import '../assets/css/custom.css';

import {Link, useParams} from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import {Container,Row, Col, Modal, Button, /*Spinner, */} from 'react-bootstrap';
import Menu from '../components/Menu';
import CardEvento from '../components/CardEvento';
import Footer from '../components/Footer';
import {motion} from 'framer-motion';
import WCService from '../services/WCService';
import Spinner from '../components/Spinner';




export default function AttivitaSingola(props){
    const [show, setShow] = useState(false);
    const [eventi, setEventi] = useState();
    const [urlHeroImage, setUrlHeroImage] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const params = useParams();
    const [tipoAttivita, setTipoAttivita] = useState('');
    const [showSpinner, setShowSpinner] = useState(true);

    useEffect(()=>{
        setTipoAttivita(params.attivita);
        getEventi(params.attivita);
        printCategoria();
    // },[]);
    // }, [props.match.params.params]);
    }, [window.location.pathname]);
    


    const heroImages = {
        climbing    : "assets/img/categories/climbing.jpg",
        mountainbike: "assets/img/categories/mountainbike.jpg",
        canoa       : "assets/img/categories/canoa.jpg",
    }

    async function getEventi(tipoAttivita){
        const a1 = await WCService.getProductsCategoryIdByCategorySlug(tipoAttivita);
        const a2 = await WCService.getProductsByCategoryID(a1.data[0].id);
        setEventi(a2.data);
        setShowSpinner(false);

        // console.warn({
        //     primo: a1.data[0].id,
        //     secondo: a2.data
        // });
    }
    
    function printCategoria(){
        const url = window.location.pathname;
        const lastSegment = url.split("/").pop();
        const urlHeroImage = '/' + heroImages[lastSegment];
        setUrlHeroImage(urlHeroImage);
    }




    return (
        <motion.div
            // className="block"
            // onClick={() => setIsActive(!isActive)}
            animate={{
                // rotate: isActive ? 180 : 360
                // rotate: [0, 0, 270, 270, 0]
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
        {/* <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        > */}
            <div className="page-header min-vh-75" style={{backgroundImage: `url(${urlHeroImage})`}}>
                <Container>
                    <Spinner show={showSpinner}/>
                    <Row className="pt-2 pb-5">
                        <Col><Menu /></Col>
                    </Row>
                    <Row className="pt-1 mt-5">
                        <Col>
                            <div className="col-lg-5 text-center mx-auto heroStyle">
                                <h1 className="pt-3 mt-n5 titleCapitalized">{tipoAttivita}</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <hr className="horizontal dark mb-5" style={{visibility: "hidden"}} />

            <Container>
                <Row>
                    {eventi && eventi.length ?
                        eventi.map((evento, index) => (
                        <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                            <CardEvento {...evento} />
                        </div>    
                    )) : null}
                    {/* <div key={index}>{JSON.stringify(evento)}</div> */}
                    

                    {/* {eventi.map((evento, i) => {
                        <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                            <CardEvento {...evento} />
                        </div>
                    })} */}

                    {/* <div>{JSON.stringify(eventi)}</div> */}
                    {/* {eventi.map((evento, i) => {
                        console.log(i);
                        return (
                            <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                                <CardEvento {...evento} />
                            </div>
                        )
                    })} */}
                </Row>
            </Container>

            <Footer siteTitle = {process.env.REACT_APP_NAME} />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </motion.div>
    );
}
