import { 
    Card, 
    Modal, 
    Button,
    Spinner, 
    ListGroup, 
  } from 'react-bootstrap';
import {
    Link 
} from "react-router-dom";


function CardEvento(props){
    const image = props.image;



    return (
        <>
            <Card>
                {/* <Card.Img variant="top" src ={image} /> */}
                <Card.Img variant="top" src = {props.images[0].src} />
                <Card.Body>
                    <Card.Title>{props.name}</Card.Title>
                    <Card.Text dangerouslySetInnerHTML={{ __html: props.short_description }}></Card.Text>

                    <ListGroup variant="flush">
                        <ListGroup.Item>{props.acf.telefono}</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                    {/* <Link to={props.permalink}> */}
                    <Link to={props.slug}>
                        <Button variant="primary">Vai all'evento</Button>
                    </Link>
                </Card.Body>
            </Card>
            {/*
            <div className="card h-100 p-3">
                <div className="overflow-hidden position-relative border-radius-lg bg-cover h-100" style={{backgroundImage: 'url(\'../assets/img/ivancik.jpg\')'}}>
                    <span className="mask bg-gradient-dark"></span>
                    <div className="card-body position-relative z-index-1 d-flex flex-column h-100 p-3">
                        <h5 className="text-white font-weight-bolder mb-4 pt-2">Work with the rockets</h5>
                        <p className="text-white">Wealth creation is an evolutionarily recent positive-sum game. It is all about who take the opportunity first.</p>
                        <a className="text-white text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                            Read More
                            <i className="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
            */}
        </>
    );

}



export default CardEvento;