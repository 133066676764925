import { useState, useEffect } from 'react';
import { 
    Container,
    Row, 
    Col, 
    Modal, 
    Button,
    Navbar,
    Nav, 
    NavDropdown, 
    // Spinner, 
  } from 'react-bootstrap';
import {motion} from 'framer-motion';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import WPService from '../services/WPService';
import Spinner from '../components/Spinner';




function IlProgetto() {
    
    const [showSpinner, setShowSpinner] = useState(true);
    const [page, setPage] = useState([]);
    const pageSlug = 'il-progetto';


    useEffect(() => {
        getPage();
    },[]);


    async function getPage () {
        const pageRetrieved = await WPService.getPageBySlug(pageSlug);
        // console.warn(pageRetrieved.data);
        setPage(pageRetrieved.data)
        setShowSpinner(false);
    }


    return (
        <motion.div
            // className="block"
            // onClick={() => setIsActive(!isActive)}
            animate={{
                // rotate: isActive ? 180 : 360
                // rotate: [0, 0, 270, 270, 0]
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
        {/* <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        > */}

            <Hero 
                title="ATTIVITA'"
                subTitle="É difficile rappresentare una Regione con una semplice app, ma il mio intento é quello di provarci." 
            />
            <Container>
                <Spinner show={showSpinner}/>
                <Row className='row align-items-center'>
                    {page && page.length ?
                        page.map((pageSingle, index) => {
                            return <Col className='col-lg-12 ms-auto' key={index}
                                dangerouslySetInnerHTML={{ __html: pageSingle.content.rendered }}>
                                </Col>
                        }) : null}

                    {/* <Col className='col-lg-4 ms-auto me-auto p-lg-4 mt-lg-0 mt-4'>
                        <div className="card card-background card-background-mask-primary tilt" data-tilt="" 
                        style={{
                            willChange: `transform; transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)`
                        }}
                        >
                            <div className="full-background" 
                            style={{
                                backgroundImage: `url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/team-working.jpg')`
                            }}>
                            </div>
                            <div className="card-body pt-7 text-center">
                                <div className="icon icon-lg up mb-3 mt-3">
                                    <svg width="50px" height="50px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <title>box-3d-50</title>
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-2319.000000, -291.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g transform="translate(1716.000000, 291.000000)">
                                        <g id="box-3d-50" transform="translate(603.000000, 0.000000)">
                                        <path d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"></path>
                                        <path d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z" opacity="0.7"></path>
                                        <path d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z" opacity="0.7"></path>
                                        </g></g></g></g>
                                    </svg>
                                </div>
                                <h2 className="text-white up mb-0">Feel the <br/> Soft UI Design System</h2>
                                <a href=".//sections/elements/buttons.html" target="_blank" className="btn btn-outline-white mt-5 up btn-round">Start with Elements</a>
                            </div>
                        </div>
                    </Col> */}
                    {/* <Col className='col-lg-6 ms-auto'>
                        <h3>La storia</h3>
                        <p>Questo progetto è nato diversi anni fa con uno scopo semplice: essere un diario di viaggio dell'autore (che sarei io)</p>
                        <p>Appassionato da sempre di Mountainbike e di tutto ciò che ha le ruote avevo pensato di raccontare la mia terra a cavallo della mia bici</p>
                        <p>Con il passare degli anni e la diminuzione del tempo libero, mi sono reso conto che non sarei riuscito a portare questo blog dove avrei voluto</p>
                        <p>Inoltre non tutti i percorsi ben si prestano al racconto mediante foto. Mi spiego meglio:</p>
                        <p>Alla classica escursione effettuata con una mountainbike di tipologia Trail/Cross country affianco uscite più "spinte", in sella ad una bici da Enduro </p>
                        <p>Questo si traduce in una maggiore difficoltà nel rendere l'idea del percorso, spesso più tecnico, unito alla parte più egoista di me che si può tradurre così:</p>
                        <blockquote>Quando sono in bici su una discesa non va di fermarmi a fare foto :D</blockquote>
                        <p></p>
                        <h3>La tecnologia</h3>
                        <p>Questo progetto è stato sviluppato con le seguenti tecnologie:</p>
                        <p><strong>Frontend</strong></p>
                        <ul>
                            <li>ReactJs</li>
                            <li>React Router V6</li>
                            <li>Axios</li>
                            <li>Bootstrap 5</li>
                        </ul>
                        <p><strong>Backend</strong></p>
                        <ul>
                            <li>Laravel 8</li>
                        </ul>
                    </Col>
                    */}
                </Row>
            </Container>
            <Footer siteTitle = {process.env.REACT_APP_NAME} />
        </motion.div>
    )
}

export default IlProgetto;