import logo from './logo.svg';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import AnimatedRoutes from './pages/AnimatedRoutes';
import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    useLocation,
    Link,
    Navigate,
    Outlet
  } from "react-router-dom";



function App() {
  return (
      <BrowserRouter>
          <AnimatedRoutes />
      </BrowserRouter>
  );
}

export default App;
