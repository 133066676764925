import {Container,Row, Col, Modal, Button, Card, ListGroup, /*Spinner,*/ } from 'react-bootstrap';
import {React, useState, useEffect } from 'react';
import {BrowserRouter, Routes, Route, useParams, useLocation, Link, Navigate, Outlet} from "react-router-dom";
import WCService from '../services/WCService';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import {motion} from 'framer-motion';
import Spinner from '../components/Spinner';




function DettaglioEvento(props){
    const image = props.image;
    const [urlHeroImage, setUrlHeroImage] = useState('');
    const [tipoAttivita, setTipoAttivita] = useState('');
    const [showSpinner, setShowSpinner] = useState(true);
    const location = useLocation();
    const params = useParams();
    const [dettaglioEvento, setDettaglioEvento] = useState([]);

    async function getProduct(){
        const res = await WCService.getProductBySlug(params.singola);
        console.info(res.data);
        setShowSpinner(false);
        setDettaglioEvento(res.data);
    }

    useEffect(()=>{
        getProduct();
        console.warn([
            location,
            params
        ]);
    }, []);


    return (
        <motion.div
            // className="block"
            // onClick={() => setIsActive(!isActive)}
            animate={{
                // rotate: isActive ? 180 : 360
                // rotate: [0, 0, 270, 270, 0]
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
            {dettaglioEvento && dettaglioEvento.length ?
                dettaglioEvento.map((evento, index) => (
                    <div key={index}>
                        <div className="page-header min-vh-75" style={{backgroundImage: `url(${evento.images[0].src})`}}>
                            <Container>
                                <Spinner show={showSpinner}/>
                                <Row className="pt-2 pb-5">
                                    <Col><Menu /></Col>
                                </Row>
                                <Row className="pt-1 mt-5">
                                    <Col>
                                        <div className="col-lg-5 text-center mx-auto heroStyle">
                                            <h1 className="pt-3 mt-n5 titleCapitalized">{evento.name}</h1>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <hr className="horizontal dark mb-5" style={{visibility: "hidden"}} />

                        <Container>
                            <Row>
                                <Card>
                                    {/* <Card.Img variant="top" src = {evento.images[0].src} /> */}
                                    <Card.Body>
                                        <Card.Title>{evento.name}</Card.Title>
                                        <Card.Text dangerouslySetInnerHTML={{ __html: evento.description }}></Card.Text>
                    
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>{evento.acf.telefono}</ListGroup.Item>
                                            <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                                            <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                                        </ListGroup>
                                        <Link to={evento.permalink}>
                                            <Button variant="primary">Vai all'evento</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Row>
                        </Container>

                        <Footer siteTitle = {process.env.REACT_APP_NAME} />
                    </div>
            )) : null}
            {/*
            <div className="card h-100 p-3">
                <div className="overflow-hidden position-relative border-radius-lg bg-cover h-100" style={{backgroundImage: 'url(\'../assets/img/ivancik.jpg\')'}}>
                    <span className="mask bg-gradient-dark"></span>
                    <div className="card-body position-relative z-index-1 d-flex flex-column h-100 p-3">
                        <h5 className="text-white font-weight-bolder mb-4 pt-2">Work with the rockets</h5>
                        <p className="text-white">Wealth creation is an evolutionarily recent positive-sum game. It is all about who take the opportunity first.</p>
                        <a className="text-white text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                            Read More
                            <i className="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
            */}

        </motion.div>
    );

}



export default DettaglioEvento;