import { useState } from 'react';
import { useParams } from "react-router-dom";
import { 
    Container,
    Navbar,
    Nav, 
    NavDropdown, 
} from 'react-bootstrap';
import {motion} from 'framer-motion';



function Mimmo() {

    let params = useParams();
    const [parametro, setParametro] = useState(params.mimmoId);
    let idDelMimmo = (
        <>
            {params.mimmoId}
        </>
    ); 
  
    return (
        <motion.div
            // className="block"
            // onClick={() => setIsActive(!isActive)}
            animate={{
                // rotate: isActive ? 180 : 360
                // rotate: [0, 0, 270, 270, 0]
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
        {/* <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        > */}
            <h1>MIMMO {idDelMimmo}</h1>
        </motion.div>
    )
}

export default Mimmo;