import logo from '../logo.svg';
import {useParams} from "react-router-dom";
import {useState, useEffect} from 'react';
import {Container, Row, Col, Modal, Button, /* Spinner */} from 'react-bootstrap';
import Menu from '../components/Menu';
import CardCustom from '../components/CardCustom';
import Footer from '../components/Footer';
import {motion} from 'framer-motion';
import WCService from '../services/WCService';
import Spinner from '../components/Spinner';



export default function Attivita(){

    const [show, setShow] = useState(false);
    const [urlHeroImage, setUrlHeroImage] = useState('');
    const [activitiesCategory, setActivitiesCategory] = useState([]);
    const [showSpinner, setShowSpinner] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const params = useParams();
    const tipoAttivita = params.attivita;
    const productCategoryExcluded = ['uncategorized', 'pedalare-in-abruzzo'];

    const heroImages = {
        climbing    : 'categories/climbing.jpg',
        mountainbike: 'categories/mountainbike.jpg',
        canoa       : 'categories/canoa.jpg',
    }

    
    useEffect(()=>{
        getActivitiesCategory();
        printCategoria();
    },[])


    async function getActivitiesCategory(){
        const activityCategories = await WCService.getProductsCategories();
        setActivitiesCategory(activityCategories.data);
        // console.log(activityCategories.data);
        setShowSpinner(false);
    }
    
    function printCategoria(){
        const url = window.location.pathname;
        const lastSegment = url.split("/").pop();
        const urlHeroImage = 'http://localhost:3000/assets/img/' + heroImages[lastSegment];
        console.log(urlHeroImage);
        setUrlHeroImage(urlHeroImage);
    }


    return (
        <motion.div
            // className="block"
            // onClick={() => setIsActive(!isActive)}
            animate={{
                // rotate: isActive ? 180 : 360
                // rotate: [0, 0, 270, 270, 0]
                x: [2000, 0]
            }}
            transition={{ duration: 1 }}
        >
        {/* <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        > */}
            <div className="page-header min-vh-75" style={{backgroundImage: "url('./assets/img/hero.jpg')"}}>
                <Container>
                    <Spinner show={showSpinner}/>
                    <Row className="pt-2 pb-5">
                        <Col><Menu /></Col>
                    </Row>
                    <Row className="pt-1 mt-5">
                        <Col>
                            <div className="col-lg-5 text-center mx-auto heroStyle">
                                <h1 className="pt-3 mt-n5 hero-color">ATTIVITA'</h1>
                                <p className="lead mt-3 hero-color">
                                    Abruzzo. <br /> Un mare di esperienze <br /> Una montagna di emozioni.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    {/* <Button variant="primary" onClick={handleShow}>Launch demo modal</Button> */}
                </Container>
            </div>
            <hr className="horizontal dark mb-5" style={{visibility: "hidden"}} />

            <Container>

                <Row>
                    <h5>Un giro in bici sulla famosa Costa dei trabocchi?</h5>
                    <h5>Oppure una cavalcata tra le Gole di San Venanzio?</h5>
                    <p>Sfoglia le proposte che offre l'Abruzzo</p>
                </Row>
                <Row>
                    {activitiesCategory && activitiesCategory.length ?
                        activitiesCategory.map((activityCategory, index) => {
                            if( !productCategoryExcluded.includes(activityCategory.slug) ){
                                return <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                    <CardCustom {...activityCategory} />
                                </div>
                            }
                        }) : null}
                    {/* {activitiesCategory && activitiesCategory.length ?
                        activitiesCategory.map((activityCategory, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                <CardCustom {...activityCategory} />
                            </div>
                    )) : null} */}
                </Row>
            </Container>

            <Footer siteTitle = {process.env.REACT_APP_NAME} />

            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </motion.div>
    );
}
       